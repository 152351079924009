// Here you can add other styles


.justify-content-space-between {
  display        : flex;
  justify-content: space-between;


  // justify-content: space-evenly;
  // justify-content: ;
}

.justify-content-flex-end {
  display        : flex;
  justify-content: flex-end;
}

.add-button {
  display        : flex;
  flex-direction : row;
  justify-content: center;
  align-items    : center;

  svg {
    margin-right: 0.4rem !important;
  }
}

.add-cbutton {
  margin-top  : 10px;
  margin-right: 6px;

  @media screen and (min-width: 1920px) {
    margin-right: 20px;
  }
}

/* NO BUTTON STYLE */

.nobutton {
  background: none;
  color     : inherit;
  border    : none;
  padding   : 0;
  font      : inherit;
  cursor    : pointer;
  outline   : inherit;
}

.nobutton:focus {
  outline: none !important;
}

/* font awersome */
table.mdb-dataTable thead .sorting:before,
table.mdb-dataTable thead .sorting_asc:before,
table.mdb-dataTable thead .sorting_desc:before,
table.mdb-dataTable thead .sorting_asc_disabled:before,
table.mdb-dataTable thead .sorting_desc_disabled:before,
table thead.mdb-dataTable-head .sorting:before,
table thead.mdb-dataTable-head .sorting_asc:before,
table thead.mdb-dataTable-head .sorting_desc:before,
table thead.mdb-dataTable-head .sorting_asc_disabled:before,
table thead.mdb-dataTable-head .sorting_desc_disabled:before {
  font-family: "Font Awesome 6 Free" !important;
}

table.mdb-dataTable thead .sorting:after,
table.mdb-dataTable thead .sorting_asc:after,
table.mdb-dataTable thead .sorting_desc:after,
table.mdb-dataTable thead .sorting_asc_disabled:after,
table.mdb-dataTable thead .sorting_desc_disabled:after,
table thead.mdb-dataTable-head .sorting:after,
table thead.mdb-dataTable-head .sorting_asc:after,
table thead.mdb-dataTable-head .sorting_desc:after,
table thead.mdb-dataTable-head .sorting_asc_disabled:after,
table thead.mdb-dataTable-head .sorting_desc_disabled:after {
  font-family: "Font Awesome 6 Free" !important;
}

div.mdb-datatable div.mdb-datatable-length select,
div.mdb-datatable div.mdb-datatable-length input {
  padding-right: 20px;
}

div.mdb-datatable div.mdb-datatable-info,
div.mdb-datatable div.mdb-dataTables_paginate,
div.mdb-datatable div.mdb-datatable-entries {
  padding-left : 0rem !important;
  padding-right: 1rem !important;
}

/* GRID */
.grid-container {
  display              : grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap             : 20px;
}

/* LOADING */
.search-loading {
  position: sticky;
  left    : 50%;
}

@media only screen and (max-width : 480px) {
  .search-loading {
    position : absolute;
    top      : 50%;
    left     : 50%;
    transform: translate(-50%, -50%);
  }
}
